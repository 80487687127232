import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

import {computed, onMounted, ref, toRefs, watch} from "vue";
import InputComponent from "@/components/home/input/InputComponent.vue";
import TextareaComponent from "@/components/home/input/TextareaComponent.vue";
import AiBlockComponent from "@/components/home/input/AiBlockComponent.vue";
import {useSettingsStore} from "@/stores/settingsStore";
import {storeToRefs} from "pinia";
import HashtagGroupComponent from "@/components/home/HashtagGroupComponent.vue";
import {useHashTagsStore} from "@/stores/hashTagsStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'TabContentComponent',
  props: {
    activeTab: {},
    hardValidate: { type: Boolean }
  },
  emits: ['update:hashTags', 'update:location', 'update:description', 'update:price'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const maxCount = ref<number>(parseInt(process.env.VUE_APP_HASHTAGS_MAX_COUNT));

const hashTags = ref<string[]>([]);
const location = ref("");
const description = ref("");
const price = ref("");

const isDescriptionDisabled = ref(false);

const {activeTab} = toRefs(props);

const {enableTextEnhance, enableHashTags} = storeToRefs(useSettingsStore());
const {hashTagsData} = storeToRefs(useHashTagsStore());

const isActiveTab = computed(() => {
  return 0 === activeTab.value
});

const changeDescription = (data: string) => {
  description.value = data;
};

const changeDescriptionDisabling = () => {
  isDescriptionDisabled.value = !isDescriptionDisabled.value;
};

onMounted(() => {
      let originalHeight = window.innerHeight;

      window.addEventListener("resize", () => {
        const currentHeight = window.innerHeight;

        if (currentHeight < originalHeight) {
          const activeElement = document.activeElement as HTMLElement;

          if (activeElement.className === "INPUT" || activeElement.tagName === "TEXTAREA") {
            activeElement.scrollIntoView({behavior: "smooth", block: "center"});
          }
        }
      });
    }
);

watch(() => hashTags.value, () => emit('update:hashTags', hashTags.value));
watch(() => location.value, () => emit('update:location', location.value));
watch(() => description.value, () => emit('update:description', description.value));
watch(() => price.value, () => emit('update:price', price.value));

return (_ctx: any,_cache: any) => {
  return (isActiveTab.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_unref(enableHashTags) && _unref(hashTagsData).length >= 1)
          ? (_openBlock(), _createBlock(HashtagGroupComponent, {
              key: 0,
              modelValue: hashTags.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((hashTags).value = $event)),
              "label-text": "Производитель",
              buttons: _unref(hashTagsData),
              "max-count": maxCount.value
            }, null, 8, ["modelValue", "buttons", "max-count"]))
          : _createCommentVNode("", true),
        _createVNode(InputComponent, {
          id: "price",
          modelValue: price.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((price).value = $event)),
          "empty-message": "Укажите стоимость",
          "label-text": "Стоимость",
          placeholder: "Цена в российских рублях",
          type: "number",
          hardValidate: _ctx.hardValidate
        }, null, 8, ["modelValue", "hardValidate"]),
        _createVNode(InputComponent, {
          id: "location",
          modelValue: location.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((location).value = $event)),
          "empty-message": "Укажите ваш город",
          "label-text": "Местоположение",
          placeholder: "Ваш город",
          type: "text",
          hardValidate: _ctx.hardValidate
        }, null, 8, ["modelValue", "hardValidate"]),
        _createVNode(TextareaComponent, {
          "empty-message": "Без описания нельзя",
          id: "description",
          "label-text": "Описание",
          modelValue: description.value,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((description).value = $event)),
          placeholder: "Текст вашего объявления",
          "max-length": 500,
          hardValidate: _ctx.hardValidate,
          isDisabled: isDescriptionDisabled.value
        }, _createSlots({ _: 2 }, [
          (_unref(enableTextEnhance))
            ? {
                name: "subspace",
                fn: _withCtx(() => [
                  _createVNode(AiBlockComponent, {
                    text: description.value,
                    onEnhancedText: changeDescription,
                    onChangeDisabling: changeDescriptionDisabling
                  }, null, 8, ["text"])
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["modelValue", "hardValidate", "isDisabled"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_unref(enableHashTags) && _unref(hashTagsData).length >= 1)
          ? (_openBlock(), _createBlock(HashtagGroupComponent, {
              key: 0,
              modelValue: hashTags.value,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((hashTags).value = $event)),
              "label-text": "Производитель",
              buttons: _unref(hashTagsData),
              "max-count": maxCount.value
            }, null, 8, ["modelValue", "buttons", "max-count"]))
          : _createCommentVNode("", true),
        _createVNode(InputComponent, {
          id: "location",
          modelValue: location.value,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((location).value = $event)),
          "empty-message": "Укажите ваш город",
          "label-text": "Местоположение",
          placeholder: "Ваш город",
          type: "text",
          hardValidate: _ctx.hardValidate
        }, null, 8, ["modelValue", "hardValidate"]),
        _createVNode(TextareaComponent, {
          "empty-message": "Без описания нельзя",
          id: "description",
          "label-text": "Описание",
          modelValue: description.value,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((description).value = $event)),
          placeholder: "Описание",
          "max-length": 500,
          hardValidate: _ctx.hardValidate,
          isDisabled: isDescriptionDisabled.value
        }, _createSlots({ _: 2 }, [
          (_unref(enableTextEnhance))
            ? {
                name: "subspace",
                fn: _withCtx(() => [
                  (_unref(enableTextEnhance))
                    ? (_openBlock(), _createBlock(AiBlockComponent, {
                        key: 0,
                        text: description.value,
                        onEnhancedText: changeDescription,
                        onChangeDisabling: changeDescriptionDisabling
                      }, null, 8, ["text"]))
                    : _createCommentVNode("", true)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["modelValue", "hardValidate", "isDisabled"])
      ]))
}
}

})