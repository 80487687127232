<template>
  <div v-if="isActiveTab">
    <HashtagGroupComponent
        v-if="enableHashTags && hashTagsData.length >= 1"
        v-model="hashTags"
        label-text="Производитель"
        :buttons="hashTagsData"
        :max-count="maxCount"/>

    <InputComponent id="price"
                    v-model="price"
                    empty-message="Укажите стоимость"
                    label-text="Стоимость"
                    placeholder="Цена в российских рублях"
                    type="number"
                    :hardValidate="hardValidate"/>

    <InputComponent id="location"
                    v-model="location"
                    empty-message="Укажите ваш город"
                    label-text="Местоположение"
                    placeholder="Ваш город"
                    type="text"
                    :hardValidate="hardValidate"/>

    <TextareaComponent empty-message="Без описания нельзя"
                       id="description"
                       label-text="Описание"
                       v-model="description"
                       placeholder="Текст вашего объявления"
                       :max-length="500"
                       :hardValidate="hardValidate"
                        :isDisabled="isDescriptionDisabled">
      <template #subspace v-if="enableTextEnhance">
        <AiBlockComponent :text="description"
                          @enhancedText="changeDescription"
                          @changeDisabling="changeDescriptionDisabling"/>
      </template>
    </TextareaComponent>
  </div>
  <div v-else>
    <HashtagGroupComponent
        v-if="enableHashTags && hashTagsData.length >= 1"
        v-model="hashTags"
        label-text="Производитель"
        :buttons="hashTagsData"
        :max-count="maxCount"/>

    <InputComponent id="location"
                    v-model="location"
                    empty-message="Укажите ваш город"
                    label-text="Местоположение"
                    placeholder="Ваш город"
                    type="text"
                    :hardValidate="hardValidate"/>

    <TextareaComponent empty-message="Без описания нельзя" id="description" label-text="Описание"
                       v-model="description" placeholder="Описание" :max-length="500"
                       :hardValidate="hardValidate"
                       :isDisabled="isDescriptionDisabled">
      <template #subspace v-if="enableTextEnhance">
        <AiBlockComponent v-if="enableTextEnhance"
                          :text="description"
                          @enhancedText="changeDescription"
                          @changeDisabling="changeDescriptionDisabling"/>
      </template>
    </TextareaComponent>
  </div>
</template>

<script setup lang="ts">
import {computed, defineEmits, defineProps, onMounted, ref, toRefs, watch} from "vue";
import InputComponent from "@/components/home/input/InputComponent.vue";
import TextareaComponent from "@/components/home/input/TextareaComponent.vue";
import AiBlockComponent from "@/components/home/input/AiBlockComponent.vue";
import {useSettingsStore} from "@/stores/settingsStore";
import {storeToRefs} from "pinia";
import HashtagGroupComponent from "@/components/home/HashtagGroupComponent.vue";
import {useHashTagsStore} from "@/stores/hashTagsStore";

const props = defineProps<{
  activeTab: number,
  hardValidate: boolean
}>();

const emit = defineEmits(['update:hashTags', 'update:location', 'update:description', 'update:price']);

const maxCount = ref<number>(parseInt(process.env.VUE_APP_HASHTAGS_MAX_COUNT));

const hashTags = ref<string[]>([]);
const location = ref("");
const description = ref("");
const price = ref("");

const isDescriptionDisabled = ref(false);

const {activeTab} = toRefs(props);

const {enableTextEnhance, enableHashTags} = storeToRefs(useSettingsStore());
const {hashTagsData} = storeToRefs(useHashTagsStore());

const isActiveTab = computed(() => {
  return 0 === activeTab.value
});

const changeDescription = (data: string) => {
  description.value = data;
};

const changeDescriptionDisabling = () => {
  isDescriptionDisabled.value = !isDescriptionDisabled.value;
};

onMounted(() => {
      let originalHeight = window.innerHeight;

      window.addEventListener("resize", () => {
        const currentHeight = window.innerHeight;

        if (currentHeight < originalHeight) {
          const activeElement = document.activeElement as HTMLElement;

          if (activeElement.className === "INPUT" || activeElement.tagName === "TEXTAREA") {
            activeElement.scrollIntoView({behavior: "smooth", block: "center"});
          }
        }
      });
    }
);

watch(() => hashTags.value, () => emit('update:hashTags', hashTags.value));
watch(() => location.value, () => emit('update:location', location.value));
watch(() => description.value, () => emit('update:description', description.value));
watch(() => price.value, () => emit('update:price', price.value));
</script>

<style scoped lang="scss">
@import "@/assets/styles/theme-colors";

@include themify($themes) {
  .list {
    background-color: themed("background-main");
  }
}

.list {
  padding-bottom: 1.7vw;
  border-radius: 10px;
  margin-top: 2.4vw;
  padding-left: 4.3vw;
  box-sizing: border-box;
  overscroll-behavior: contain;
}
</style>