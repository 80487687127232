import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "button-group" }
const _hoisted_2 = { class: "button-list" }

import {computed, toRefs, ref, watch} from 'vue';
import HashtagButtonComponent from "@/components/home/button/HashtagButtonComponent.vue";

import {HashtagInterface} from "@/interfaces/HashtagInterface";
import {Analytics} from "@/analytics/Analytics";
import {getCurrentInstance} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HashtagGroupComponent',
  props: {
    labelText: {},
    buttons: {},
    modelValue: {},
    maxCount: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const analytics: Analytics = getCurrentInstance()?.appContext.config.globalProperties.analytics;

const props = __props;

const emit = __emit;

const {labelText, buttons, maxCount, modelValue} = toRefs(props);

const hashTags = ref<string[]>([]);

const sortedButtons = computed(() => {
  return buttons.value
      .filter(button => button.visible)
      .sort((a, b) => a.score - b.score);
});

const sortedHashtags = computed(() => {
  return hashTags.value
      .slice()
      .sort((a, b) => {
        const buttonA = buttons.value.find(button => button.hashTag === a);
        const buttonB = buttons.value.find(button => button.hashTag === b);

        if (!buttonA || !buttonB) return 0;
        return buttonA.score - buttonB.score;
      });
});

const isButtonDisabled = computed(() => {
  return (hashtag: string): boolean => {
    return !hashTags.value.includes(hashtag) && hashTags.value.length >= maxCount.value;
  }
});

const handleAdd = (hashtag: string, displayName: string): void => {
  analytics.sendEvent("Hashtag", {
    Action: "Selected",
    Name: displayName
  });

  hashTags.value.push(hashtag);
};

const handleRemove = (hashtag: string, displayName: string): void => {
  analytics.sendEvent("Hashtag", {
    Action: "Removed",
    Name: displayName
  });

  hashTags.value = modelValue.value.filter((item) => item !== hashtag);
};

watch(sortedHashtags, (newValue) => {
  emit('update:modelValue', newValue);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, _toDisplayString(_unref(labelText)), 1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortedButtons.value, (button) => {
        return (_openBlock(), _createBlock(HashtagButtonComponent, {
          key: button.hashTag,
          displayName: button.displayName,
          isDisabled: isButtonDisabled.value(button.hashTag),
          onAdd: ($event: any) => (handleAdd(button.hashTag, button.displayName)),
          onRemove: ($event: any) => (handleRemove(button.hashTag, button.displayName))
        }, null, 8, ["displayName", "isDisabled", "onAdd", "onRemove"]))
      }), 128))
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("span", {
      class: _normalizeClass({'error-message': true, 'hidden': true })
    }, "%placeholder%", -1))
  ]))
}
}

})