<template>
  <div class="button-group">
    <label>{{ labelText }}</label>
    <div class="button-list">
      <HashtagButtonComponent
          v-for="(button) in sortedButtons"
          :key="button.hashTag"
          :displayName="button.displayName"
          :isDisabled="isButtonDisabled(button.hashTag)"
          @add="handleAdd(button.hashTag, button.displayName)"
          @remove="handleRemove(button.hashTag, button.displayName)"
      />
    </div>
    <span :class="{'error-message': true, 'hidden': true }">%placeholder%</span>
  </div>
</template>

<script setup lang="ts">
import {computed, toRefs, defineProps, ref, watch} from 'vue';
import HashtagButtonComponent from "@/components/home/button/HashtagButtonComponent.vue";
import {defineEmits} from "vue";
import {HashtagInterface} from "@/interfaces/HashtagInterface";
import {Analytics} from "@/analytics/Analytics";
import {getCurrentInstance} from "vue";

const analytics: Analytics = getCurrentInstance()?.appContext.config.globalProperties.analytics;

const props = defineProps<{
  labelText: string;
  buttons: HashtagInterface[];
  modelValue: string[];
  maxCount: number
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: string[]): void;
}>();

const {labelText, buttons, maxCount, modelValue} = toRefs(props);

const hashTags = ref<string[]>([]);

const sortedButtons = computed(() => {
  return buttons.value
      .filter(button => button.visible)
      .sort((a, b) => a.score - b.score);
});

const sortedHashtags = computed(() => {
  return hashTags.value
      .slice()
      .sort((a, b) => {
        const buttonA = buttons.value.find(button => button.hashTag === a);
        const buttonB = buttons.value.find(button => button.hashTag === b);

        if (!buttonA || !buttonB) return 0;
        return buttonA.score - buttonB.score;
      });
});

const isButtonDisabled = computed(() => {
  return (hashtag: string): boolean => {
    return !hashTags.value.includes(hashtag) && hashTags.value.length >= maxCount.value;
  }
});

const handleAdd = (hashtag: string, displayName: string): void => {
  analytics.sendEvent("Hashtag", {
    Action: "Selected",
    Name: displayName
  });

  hashTags.value.push(hashtag);
};

const handleRemove = (hashtag: string, displayName: string): void => {
  analytics.sendEvent("Hashtag", {
    Action: "Removed",
    Name: displayName
  });

  hashTags.value = modelValue.value.filter((item) => item !== hashtag);
};

watch(sortedHashtags, (newValue) => {
  emit('update:modelValue', newValue);
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/theme-colors";

@include themify($themes) {
  .button-group {
    label {
      color: themed('text');
    }
  }
}

.button-group {
  z-index: 1000;
  width: 100%;
  height: 100%;
  margin-bottom: 0.4rem;
  padding-bottom: 1px;

  .button-list {
    display: flex;
    gap: .55rem;

    margin: 0 -1rem;
    padding: 0 1rem;

    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  label {
    font-size: 0.9rem;
    display: block;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .error-message {
    display: block;
    font-size: 0.875rem;
    margin-top: 0.35rem;
  }

  .hidden {
    visibility: hidden;
  }
}
</style>
