<template>
  <button
      :class="['button', { active: isActive }]"
      :disabled="isDisabled"
      @click="handleClick"
  >
    {{ displayName }}
  </button>
</template>

<script setup lang="ts">
import {defineProps, defineEmits, toRefs, ref} from 'vue';

const props = defineProps({
  displayName: {
    type: String,
    required: true,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
});

const {displayName, isDisabled} = toRefs(props);

const emit = defineEmits(['add', 'remove']);

const isActive = ref(false);

const handleClick = (): void => {
  if (!isDisabled?.value) {
    if (!isActive.value) {
      emit('add');
    } else {
      emit('remove');
    }

    isActive.value = !isActive.value
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/theme-colors";

@include themify($themes) {
  .button {
    border: 1px solid themed('accent-gray-3');
    color: themed('text');

    &.active {
      background-color: themed('button-active-background');
      border-color: themed('button');
    }

    &:disabled {
      color: themed('accent-gray');
      background-color: themed('accent-gray-6');
      border-color: themed('accent-gray-6');
    }
  }
}

.button {
  padding: 0.4rem .9rem;
  border-radius: .65rem;
  cursor: pointer;
  background-color: transparent;

  font-size: 1rem;
  white-space: nowrap;

  &:disabled {
    cursor: not-allowed;
  }
}
</style>
