import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

import {toRefs, ref} from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'HashtagButtonComponent',
  props: {
  displayName: {
    type: String,
    required: true,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
},
  emits: ['add', 'remove'],
  setup(__props, { emit: __emit }) {

const props = __props;

const {displayName, isDisabled} = toRefs(props);

const emit = __emit;

const isActive = ref(false);

const handleClick = (): void => {
  if (!isDisabled?.value) {
    if (!isActive.value) {
      emit('add');
    } else {
      emit('remove');
    }

    isActive.value = !isActive.value
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(['button', { active: isActive.value }]),
    disabled: _unref(isDisabled),
    onClick: handleClick
  }, _toDisplayString(_unref(displayName)), 11, _hoisted_1))
}
}

})