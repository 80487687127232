import {defineStore} from 'pinia';
import {ref} from 'vue';

export const useSettingsStore = defineStore('settings', () => {
    const enableTextEnhance = ref(false);
    const enableHashTags = ref(false);

    const setTextEnhance = (status: boolean) => {
        enableTextEnhance.value = status;
    }

    const fetchSettings = async () => {
        try {
            const response = await fetch(`${process.env.VUE_APP_API_URL}/settings`);

            const data = await response.json();

            enableTextEnhance.value = data.find((item: { id: number, key: string, value: boolean }) =>
                item.key === 'enableTextEnhance'
            )?.value ?? false;

            enableHashTags.value = data.find((item: {
                    id: number,
                    key: string,
                    value: boolean
                }) =>
                    item.key === 'enableHashTags'
            )?.value ?? false;

        } catch (error) {
            console.error('Ошибка при загрузке настроек:', error);
        }
    };

    return {enableTextEnhance, enableHashTags, setTextEnhance, fetchSettings};
});
