import {defineStore} from "pinia";
import {ref} from "vue";
import {HashtagInterface} from "@/interfaces/HashtagInterface";

export const useHashTagsStore = defineStore('hashTags', () => {
    const hashTagsData = ref<HashtagInterface[]>([]);

    const fetchHashTags = async () => {
        try {
            const response = await fetch(`${process.env.VUE_APP_API_URL}/hashtags`);

            hashTagsData.value = await response.json()
        } catch (error) {
            console.error('Ошибка при загрузке хештегов:', error);
        }
    };

    return {
        hashTagsData,
        fetchHashTags
    }
});